<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="mt-2">
                <b-col cols="12" md="12">
                        <b-form-group :label="$t('student_number')">
                        <label>{{this.student_program_id  }}</label><br>
                        <label>{{this.name + this.surname}}</label>
                        </b-form-group>
                    <ValidationProvider name="certificate_id"
                                        rules="required"
                                        v-slot="{ valid, errors }">
                        <b-form-group :label="$t('certificate_id')">
                            <b-form-select v-model="formData.certificate_id"
                                           :options="options"></b-form-select>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester')">
                            <semesters-select-box
                                :validate-error="errors[0]"
                                v-model="formData.semester_id">
                            </semesters-select-box>
                        </b-form-group>
                    </ValidationProvider>
                    <div class="d-flex justify-content-center mt-3 mb-3">
                        <b-button variant="primary"
                                  @click="createForm"
                                  :disabled="formLoading">
                            {{ $t('save') }}
                        </b-button>
                    </div>
                </b-col>

            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import SemestersSelectBox from "@/components/interactive-fields/SemestersSelectbox";

// Services
import StudentCertificateService from "@/services/StudentCertificateService";
import CertificateService from "@/services/CertificateService";

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';


export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        SemestersSelectBox
    },
    props: {
        name:{},
        surname:{},
        student_program_id: {}
    },
    data() {
        return {
            options:[],
            formData: {
                // Tab 1
            },
            formLoading: false
        }
    },
    methods: {
        takeDATA(){
          CertificateService.getAll().then((response) => {
              const data = response.data.data;
              data.map((item) => {
                  if(item.type=="coop"){
                      this.options.push({
                          value: item.id,
                          text: item.id + ' - ' + this.getLocaleText(item, 'name'),
                      })
                  }
              })
          })


        },
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formLoading = true;
                this.formData.student_program_id= this.student_program_id
                let formData = {
                    ...this.formData
                }
               StudentCertificateService.store(formData)
                    .then(response => {
                        this.$toast.success(this.$t( response.data.message));
                        this.$emit('updateFormSuccess', true);
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false;
                    })
            }
        }
    },
    async created() {
      await  this.takeDATA()
    }
}
</script>

